.welcome {
    min-height: 100vh;
    min-height: 100dvh;
    background-color: var(--lila);

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 0;
        background-color: var(--roze);
        clip-path: polygon(15% 0%, 90% 0%, 85% 100%, 10% 100%);
    }

    .swiper-pagination-bullet-active {
        background-color: var(--paars);
    }

    .welcome__introduction {
        text-align: center;
        position: relative;

        h1 {
            font-size: 2.5rem;
            font-family: var(--font-black);
            color: var(--paars);
        }

        p {
            font-size: 17px;
            font-family: var(--font-medium);
            color: var(--zwartblauw);
            margin-top: 1.5rem;
        }
    }

    .welcome__guide {
        margin: 24px 0;

        .welcome__slide {
            padding: 16px 16px 40px 16px;

            .welcome__content {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 1.5rem;

                background-color: rgba(255, 255, 255, 1);
                border-radius: var(--border-radius);
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

                .welcome__img {
                    width: 240px;
                    height: 240px;
                    clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 75% 90%, 50% 100%, 25% 90%, 0 90%);
                }

                .welcome__index {
                    font-size: 2rem;
                    font-family: var(--font-black);
                    color: var(--donkerblauw);
                    margin-bottom: 1rem;
                    margin-right: 1rem;
                }

                h2 {
                    font-size: 1.5rem;
                    font-family: var(--font-heavy);
                    margin-top: 0.5rem;
                    margin-bottom: 1rem;
                    text-align: center;
                }
                
                p {
                    text-align: center;
                    flex-grow: 1;
                }
            }
        }
    }

    .welcome__actions {
        position: relative;
        
        > div {
            display: flex;
            justify-content: center;
        }

        .welcome__skip-link {
            font-size: 14px;

            &:hover,
            &:focus {
                text-decoration: underline;
                color: var(--oranje);
            }
        }
    }

    @media (max-width: 768px) {

        &::before {
            height: 0;
        }

        .welcome__introduction {
            h1 {
                font-size: 2rem;
            }

            p {
                font-size: 1rem;
            }
        }
    }
}
