.page-not-found {
    min-height: 100vh;
    min-height: 100dvh;
    background-color: var(--oranje);

    .page-not-found__bg-img {
        width: 100%;
        height: 100%;
        position: absolute;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .page-not-found__content {
        min-height: 75vh;
        display: flex;
        align-items: center;
        color: var(--wit);
        position: relative;
    }
}
