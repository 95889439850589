.skills-selection-wrapper {
    width: 100%;

    .knowledge-container,
    .skills-container {
        padding: 1rem;
        border-radius: var(--border-radius);
        display: flex;
        flex-wrap: wrap;

        .no-source {
            color: rgba(0, 0, 0, 0.4);
        }

        .source-type-tabs {
            border: none;

            .nav-link {
                color: var(--zwartblauw);
                border: none;
                border-bottom: 2px solid rgba(0, 0, 0, 0.07);

                .wihv {
                    filter: grayscale(100%) brightness(0.4);
                }

                &.active {
                    color: var(--oranje);
                    border-bottom: 2px solid var(--oranje);

                    .wihv {
                        filter: grayscale(0) brightness(1);
                    }
                }

                &.disabled {
                    color: rgba(0, 0, 0, 0.25);
                }
            }
        }

        .profession-tab {
            padding: 24px 0 16px 0;
        }

        .source-supplier-tabs {
            box-shadow: 0 8px 17px -17px rgba(0, 0, 0, 0.4);

            .nav-item {
                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--donkerblauw);
                    border: none;
                    border-bottom: 2px solid transparent;

                    span {
                        margin-left: 6px;
                        font-size: 0.875rem;
                    }

                    &.active {
                        border-bottom: 2px solid #003399;
                    }

                    &.disabled {
                        color: rgba(0, 0, 0, 0.3);

                        img.wihv {
                            filter: grayscale(100%) brightness(1.3);
                        }

                        img.esco {
                            filter: grayscale(100%) brightness(4);
                        }
                    }
                }
            }
        }

        .function-column {
            display: flex;
            flex-direction: column;
            height: 100%;

            .droppables-wrapper {
                flex-grow: 1;
            }
        }

        .source-column,
        .function-column {
            .skill-type {
                font-size: 14px;
                font-family: var(--font-medium-italic);
                color: rgba(0, 0, 0, 0.3);
            }

            .source-options {
                display: flex;
                flex-wrap: wrap;
                gap: 0.5rem;
                max-height: 485px;
                overflow-y: auto;

                &.feedback {
                    max-height: 485px;
                }

                .skill-type {
                    width: 100%;
                    font-size: 14px;
                    font-family: var(--font-medium-italic);
                    color: rgba(0, 0, 0, 0.3);
                }
            }

            .droppables-wrapper {
                border: 2px solid rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                display: flex;
                flex-direction: column;
            }

            .droppable-container {
                min-height: 200px;
                max-height: 330px;
                overflow-y: auto;
                border-radius: 8px;
                width: 100%;

                &.optional {
                    border: 2px dashed rgba(0, 0, 0, 0.1);
                    max-height: 200px;
                }

                &.dragging-over {
                    background-color: rgba(0, 0, 0, 0.03);
                }

                .option {
                    margin-right: 8px;
                }
            }

            .option {
                user-select: none;
                display: flex;
                align-items: center;
                border: 2px solid rgba(0, 0, 0, 0.1);
                border-radius: 18px;
                background-color: var(--wit);
                position: relative;
                width: fit-content;
                font-size: 0.875rem;
                padding: 4px 8px;

                .start-icon {
                    i {
                        color: rgba(0, 0, 0, 0.3);
                    }
                }

                .drag-icon {
                    i {
                        color: rgba(0, 0, 0, 0.6);
                    }
                }

                &.selected {
                    background-color: rgba(102, 204, 145, 0.15);
                    border-color: rgba(102, 204, 145, 0.2);
                    color: rgba(51, 102, 72, 1);

                    .start-icon {
                        i {
                            color: var(--groen);
                        }
                    }
                }

                &.add {
                    cursor: pointer;
                }

                &:hover,
                &:focus {
                    background-color: #c9edf849;
                }

                &.dragging {
                    background-color: #c9edf849;
                }

                .drag-item-title {
                    font-family: var(--font-medium-italic);
                    color: rgba(0, 0, 0, 0.7);
                }

                .drag-item-info {
                    right: 5px;
                    padding: 0 9px;
                    background-color: rgba(0, 0, 0, 0.05);
                    border-radius: 50%;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }
    }
}
