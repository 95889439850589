.page-header {

    h1 {
        font-size: 3rem;
    }

    .first-content {
        padding-top: 150px;

        @media only screen and (max-width: 768px) {
            padding-top: 100px;
        }
    }

    .page-header-img {
        top: 0;
        height: 100%;
        left: 40%;
        right: 5%;
        padding-left: 15px;
        padding-right: 15px;
        pointer-events: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            clip-path: polygon(0 0, 80% 0, 100% 100%, 20% 100%);
        }

        @media (max-width: 992px) {
            display: none;
        }
    }

    &::before {
        top: 0;
        right: 0;
        height: 100%;
        content: "";
        position: absolute;
        background-color: var(--oranje);
        width: calc(40vw);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 100%);
    }

    @media (max-width: 992px) {
        &::before {
            content: none;
        }
    }
}
