.feedback-container {
    min-height: 100vh;
    min-height: 100dvh;
    background-color: var(--donkerblauw);

    .button {
        display: flex;
        align-items: center;
        width: fit-content;
        line-height: 1;
        padding: 0.5rem 1rem;
        border: 2px solid var(--wit);
        border-radius: var(--border-radius);
        color: var(--wit);
        background-color: transparent;
        font-family: var(--font-bold);
        font-size: 1.125rem;

        &:hover, &:focus {
            background-color: var(--wit);
            color: var(--donkerblauw);
        }
    }
}