.function-card {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--border-radius);
    border: 3px solid transparent;
    cursor: pointer;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 200px 1fr;

    &:hover,
    &:focus {
        outline: none;
        border: 3px solid var(--oranje);
    }

    .card-image {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(12, 23, 50, 1) 20%, rgba(12, 23, 50, 0) 80%);
        }
    }

    .card-active {
        position: absolute;
        top: 1rem;
        left: 1rem;
    }

    .card-content {
        z-index: 1;
        height: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;

        .card-title {
            color: var(--geel);
            font-size: 1.125rem;
            font-family: var(--font-black);
            margin: 0;
        }

        .card-description {
            color: var(--wit);
            font-size: 1rem;
            margin: 1rem 0;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
