@import "~bootstrap/scss/bootstrap-utilities";
@import "variables";

/* Global styles */

body {
    background-color: #fff;

    p:last-of-type {
        margin-bottom: 0;
    }
}

a {
    color: var(--zwartblauw);
}

body.noscroll,
html.noscroll {
    overflow-y: hidden;
    touch-action: none;
    -ms-touch-action: none;
}

/* width */
::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.06);
    border-radius: 1rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
}

/* Headings */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
    line-height: 1;
    overflow-wrap: normal;
    margin-bottom: 0;
}

.h1 {
    font-size: 3.75rem;
}

.h2 {
    font-size: 2.5rem;
}

.h3 {
    font-size: 1.5rem;
}

.h4 {
    font-size: 1.25rem;
}

.h5 {
    font-size: 1rem;
}

// Add padding to the bottom of the body to make sure the navbar doesn't overlap the content
.navbar-margin {
    @include media-breakpoint-only(xs) {
        padding-bottom: var(--navbar-mobile-height);
    }

    &.no-padding {
        @include media-breakpoint-only(xs) {
            padding-bottom: 0;
        }
    }

    // Add padding to the left of the container to make sure the navbar doesn't overlap the content
    .container {
        $padding-for-navbar: calc(
            var(--navbar-width) + var(--navbar-margin)
        ); // width of navbar + padding-left of navbar
        $breakpoints: map-keys($grid-breakpoints); // xs, sm, md, lg, xl, xxl
        $extra-width: 160px; // Extra width to add to the breakpoint-max

        // Loop through all breakpoints
        @each $breakpoint in $breakpoints {
            $min-width: breakpoint-min($breakpoint);
            $max-width: $min-width + $extra-width;

            @media (min-width: $min-width) and (max-width: $max-width) {
                padding-left: $padding-for-navbar;
            }
        }
    }
}
