.vacancy-upload {
    .vacancy-upload__logo {
        padding: 2px 8px;
        background-color: var(--wit);
        color: var(--oranje);
        font-family: var(--font-black);
        font-size: 13px;
        border-radius: 4px;
    }

    .vacancy-upload__suffix {
        font-size: 13px;
        font-style: italic;
    }

    .vacancy-upload__status {
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: var(--border-radius);
        color: var(--zwartblauw);

        button {
            line-height: 1.5;
            border-radius: var(--border-radius);
        }
    }

    .vacancy-upload__alert {
        padding: 8px;
        border-radius: 8px;
        margin-bottom: 1rem;
        background-color: var(--wit);
        border: 2px solid var(--zwartblauw);
        text-align: center;
    }
}
