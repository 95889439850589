.company-card {
    &.card {
        background: none;
        border: none;
    }

    .card-header {
        background-color: var(--groen);
        color: var(--geel);
        padding: 1rem 2rem;
        border-bottom: 2px solid var(--blauw);
        border-radius: 25px 25px 0 0;
        font-size: 1rem;
        font-family: var(--font-heavy);

        display: flex;
        justify-content: space-between;

        .card-title {
            margin-bottom: 0;
        }
    }

    .card-body {
        background-color: var(--groen);
        color: var(--lichtblauw);
        border: none;
        border-radius: 0 0 25px 25px;
        padding: 0 2rem 2rem 2rem;

        .field {

            &.view {
                margin-top: 0.5rem;
            }

            &.edit {
                margin-top: 1rem;
            }
        }
    }

    .profile-counters {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .counter {
            color: var(--lichtblauw);
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.1);
            padding: 0.75rem;
            border-radius: var(--border-radius);
            min-width: 110px;

            &:hover, &:focus  {
                background-color: rgba(255, 255, 255, 0.2);

                .title {
                    text-decoration: underline;
                }
            }

            .count {
                font-family: var(--font-black);
                font-size: 2rem;
                color: var(--wit);
                flex-grow: 1;
            }
        }
    }
}
