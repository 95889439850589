.talents-overview {
    min-height: 100vh;
    min-height: 100dvh;
    background-color: var(--blauw);

    .locked-info {
        z-index: 2;
        position: absolute;
        top: 130px;
        width: calc(100% - 1.5rem);
        left: 0.75rem;
        background-color: rgba(255, 255, 255, 0.4);
        border-top: 2px solid var(--blauw);
        border-bottom: 2px solid var(--blauw);

        a {
            text-decoration: underline;
        }
    }

    .placeholders {
        position: relative;

        &::after {
            content: "";
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--blauw));
        }

        @media (max-width: 990px) {
            .talent-col-2,
            .talent-col-3,
            .talent-col-4,
            .talent-col-5 {
                display: none;
            }
        }

        @media (max-width: 1199px) {
            .talent-col-4,
            .talent-col-5 {
                display: none;
            }
        }
    }

}
