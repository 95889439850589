.talent-preview {
    .video-container {
        position: relative;
        cursor: pointer;

        .video-overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 7px;
            width: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
        }

        .play-button {
            border-radius: 50%;
            width: 64px;
            height: 64px;
            background-color: var(--wit);
            color: var(--oranje);
            border: none;

            i {
                margin-right: -6px;
            }

            &:hover, &:focus {
                background-color: var(--oranje);
                color: var(--wit);
            }
        }
    }

    .favorite {
        i {
            cursor: pointer;
            color: var(--oranje);

            &:hover {
                color: darken(#fe7225, 10%);
            }
        }
    }

    .description {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        &.expanded {
            display: block;
            -webkit-line-clamp: none;
            overflow: visible;
        }
    }

    .description-btn {
        margin-top: 10px;
        font-size: 14px;
        border: none;
        background-color: transparent;
        width: 100%;
    }
}

.talent-placeholder {
    .video-container {
        width: 100%;
        aspect-ratio: 16/9;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 0.5;
    }

    i {
        color: rgba(0, 0, 0, 0.3);
    }

    .placeholder {
        color: rgba(0, 0, 0, 0.3);
    }
}
