.wihv-modal.beta-message {

    .modal-header .modal-title {
        font-size: 1.5rem;
    }

    .modal-body {
        background: var(--wit);
    }

    a:focus, a:hover {
        text-decoration: underline;
    }
}
