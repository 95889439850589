.measurement-wrapper {
    min-height: 100vh;
    min-height: 100dvh;
}

.form-measurement {

    form {

        #root__title {
            font-family: var(--font-heavy);
            font-size: 3rem;
            color: var(--donkerblauw);
            margin: 0;
            padding: 1.5rem;
            background-color: white;
            border-radius: var(--border-radius) var(--border-radius) 0 0;
        }

        #root__description {
            padding: 0 1.5rem 1.5rem 1.5rem;
            background-color: white;
            margin-bottom: 2rem;
            border-radius: 0 0 var(--border-radius) var(--border-radius);
        }

        .form-group {
            padding-bottom: 2rem;

            .control-label {
                font-family: var(--font-bold);
                font-size: 1.125rem;
                padding-bottom: 4px;

                .required {
                    margin-left: 4px;
                    color: var(--oranje);
                }
            }

            input[type=range] {
                padding: 0;
            }

            .radio, .checkbox {
                margin-bottom: 4px;

                label {
                    cursor: pointer;
                }

                label span {
                    display: flex;
                    align-items: center;
                    gap: 8px;
    
                    input[type=checkbox], input[type=radio] {
                        transform: scale(1.3);
                    }

                    &:hover {
                        color: var(--donkerblauw);
                    }
                }
            }

            .error-detail {
                .text-danger {
                    font-family: var(--font-bold);
                    font-size: 0.875rem;
                }
            }
        }
    }
}