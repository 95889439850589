.logo-container {
    position: relative;
    display: inline-block;
}

.logo {
    position: relative;
    display: inline-block;
    overflow: hidden;
    background: transparent;
    text-align: center;

    .logo-wrapper {
        position: relative;
        display: inline-block;
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid white;

        &.editable {
            cursor: pointer;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;

            &.cover {
                object-fit: cover;
            }
        }
    }

    .placeholder {
        border-radius: 50%;
    }

    .no-logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid white;
        background-color: var(--lichtblauw);

        span {
            font-size: 12px;
        }
    }

    .upload-button {
        z-index: 99;
        position: absolute;
        top: 16px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        background-color: #fff;

        input {
            display: none;
        }

        i {
            font-size: 1rem;
        }

        &.editable {
            cursor: pointer;
        }
    }
}
