.radar-chart-result {

    .radar {
        display: flex;
        justify-content: center;
        height: 500px;
        background-color: var(--wit);
        border-radius: var(--border-radius);

        @media only screen and (max-width: 1200px) {
            height: fit-content;
            width: 100%;
        }

        @media only screen and (max-width: 576px) {
            height: fit-content;
            width: 100%;
        }
    }
    
    .highlighted {
        width: fit-content;
        margin-bottom: 1rem;
        padding: 8px 16px;
        background-color: var(--zwartblauw);
        color: var(--wit);
        border-radius: var(--border-radius);
    }
}
