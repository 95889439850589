.measurement-wrapper {
    min-height: 100vh;
    min-height: 100dvh;
}

.image-selection-measurement {
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-selection {
        display: flex;
        justify-content: space-evenly;
        gap: 24px;
    }

    label {
        
        img {
            border: 4px solid var(--wit);
        }
        
        input[type="radio"]:checked + img {
            border: 4px solid var(--oranje);
        }

        input[type="radio"] {
            display: none;
        }

        .image-title {
            text-align: center;
            margin-top: 8px;
            font-weight: bold;
        }

        &:hover {
            cursor: pointer;

            img {
                border: 4px solid var(--oranje);
            }
        }
    }
}