.active-indicator {
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    line-height: 1;
    border-radius: 4rem;
    color: rgba(0, 0, 0, 0.8);
    background-color: rgba(255, 255, 255, 0.8);

    i {
        color: var(--danger);

        &.enabled {
            color: var(--success);
        }
    }

    p {
        position: relative;
        top: 1px;
    }
}