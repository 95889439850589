@import "~bootstrap/scss/bootstrap-utilities";

.header-img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    // Mobile
    @include media-breakpoint-down(md) {
        filter: blur(10px);
    }
}
