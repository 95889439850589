.result-container {
    min-height: 100vh;
    min-height: 100dvh;
    background-color: var(--lichtblauw);

    .title {
        font-family: var(--font-heavy);
        font-size: 2.5rem;
    }

    .intro {
        margin-top: 1rem;
    }

    .measurement-img {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 25%;
        }
    }

    .result-content {
        h3 {
            font-family: var(--font-heavy);
            color: var(--donkerblauw);
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }
    }
}
