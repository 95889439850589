.header {

    button {
        background: transparent;
        border: 0;
    }

    img {
        height: 36px;
        width: auto;
        object-fit: cover;
        z-index: 1030;
    }

    .logo-banner {
        position: absolute;
        top: -1000px;
        left: -0.75rem;
        right: 0;
        bottom: -1rem;
        width: calc(100% + 1.5rem);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1020;
    }

    .menu-actions {
        z-index: 1030;
        display: flex;
        align-items: center;
        padding: 8px 12px;
        gap: 8px;
        border-radius: var(--border-radius);
        background-color: rgba(0, 0, 0, 0.1);

        .header__help, .header__warning {
            color: rgba(255, 255, 255, 0.7);

            &:hover {
                color: var(--wit);
            }
        }
        
        .language-toggle {
            background: transparent;
            padding: 8px;
            font-size: 16px;
            font-family: var(--font-bold);
            border-radius: 0;
            color: var(--wit);

            &:hover, &:focus {
                background: transparent;
            }
        }

        .toggle-menu-icon {
            color: var(--wit);
        }
    }

    .main-nav {
        top: 0;
        width: 57.5%;
        right: -100%;
        height: 100%;
        z-index: 1020;
        position: fixed;
        background: var(--zwartblauw);
        clip-path: polygon(15% 0%, 100% 0, 100% 100%, 0% 100%);

        a {
            width: fit-content;
    
            @media only screen and (max-width: 768px) {
                z-index: 1030;
            }
        }

        .main-nav-wrapper {
            width: 100%;
        }

        .nav {
            .nav-link {
                display: block;
                padding: 0.5rem 1rem;
                font-size: 18px;
                font-weight: 400;
                color: var(--wit);
                padding-left: 15% !important;
                width: 100%;

                &:hover {
                    background: rgba(255, 255, 255, 0.15);
                }
            }

            .nav-item-large {
                .nav-link {
                    font-size: 26px;
                    font-weight: 900;
                }

                &.last {
                    margin-bottom: 3rem;
                }
            }
        }

        &.open {
            right: 0;
        }

        .dropdown-menu {
            top: 0;
            bottom: 0;
            margin: 0;
            border: 0;
            padding: 0;
            left: 100%;
            width: 57.5%;
            height: 100%;
            display: flex;
            border-radius: 0;
            flex-direction: column;
            justify-content: center;
            position: fixed !important;
            align-content: space-around;
            background: var(--zwartblauw);

            .close-menu .nav-link {
                position: relative;
                padding-left: 2rem;
                margin-bottom: 1.5rem;

                span {
                    font-size: 20px;
                    font-weight: 800;
                }
            }

            &.show {
                left: 42.5%;
            }

            @media only screen and (max-width: 768px) {
                top: 100px;
                justify-content: flex-start;

                &.show {
                    top: 100px;
                    left: 0%;
                    width: 100%;
                }
            }
        }

        @media only screen and (max-width: 768px) {
            width: 100%;
            clip-path: none;
            padding-top: 100px;
            height: 100%;
            align-items: flex-start !important;

            .nav {
                .nav-link {
                    padding-left: 10% !important;
                }
            }
        }
    }

    .main-nav-shadow {
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        visibility: hidden;
        background: var(--zwartblauw);

        &.show {
            z-index: 1000;
            opacity: 0.5;
            visibility: visible;
        }
    }
}
