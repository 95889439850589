.activity-card {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 260px;
    overflow: hidden;
    border-radius: var(--border-radius);
    background-color: var(--wit);

    .card-content {
        z-index: 1;
        height: 100%;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;

        .card-header {
            display: grid;
            grid-template-columns: 1fr 24px;

            .card-title {
                color: var(--paars);
                font-size: 1.125rem;
                font-family: var(--font-heavy);
                margin: 0;
            }

            .card-actions {
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    color: var(--oranje);
                }
            }
        }

        .card-actions-dropdown {
            position: absolute;
            top: 0;
            right: 0;
            width: fit-content;
            height: fit-content;
            z-index: 2;
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: var(--roze);
            border-radius: var(--border-radius);

            &.show {
                display: flex;
            }

            .card-actions-dropdown-item {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                padding: 1.25rem 1.5rem;
                width: 100%;
                line-height: 1;
                cursor: pointer;

                &:first-of-type {
                    border-radius: var(--border-radius) var(--border-radius) 0 0;
                }

                &:last-of-type {
                    border-radius: 0 0 var(--border-radius) var(--border-radius);
                }

                &:hover,
                &:focus {
                    color: var(--paars);
                }
            }
        }

        .card-description {
            flex-grow: 1;
            font-size: 1rem;
            margin: 1rem 0;

            p {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .card-icon-row {
            display: grid;
            grid-template-columns: 24px 1fr;
            align-items: center;
            padding: 0.25rem 0;

            i {
                color: var(--oranje);
            }

            p {
                line-height: 1;
            }

            a {
                line-height: 1;
                text-decoration: underline;
            }
        }
    }
}
