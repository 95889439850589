.form-card {

    &.card {
        background-color: var(--lichtblauw);
        border-radius: var(--border-radius);
        border: none;
    }

    .card-header {
        background-color: var(--wit);
        color: var(--donkerblauw);
        border: none;
        padding: 1.25rem 2rem;
        border-radius: 1rem;
        font-family: var(--font-bold);
        font-size: 1.5rem;
        cursor: pointer;

        .card-title {
            margin-bottom: 0;
        }

        .toggle-icon {
            color: var(--donkerblauw);
            line-height: 1;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.8);
        }
    }

    .card-body {
        color: var(--zwartblauw);
        border: none;
        padding: 2rem;

        &.open {
            height: auto;
        }

        &.closed {
            display: none;
            height: 0;
            padding: 0;
            overflow: hidden;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to top, var(--blauw), transparent);
                border-radius: 0 0 var(--border-radius) var(--border-radius);
            }
        }

        ul {
            padding: 0;
            line-height: 1.7;
            font-size: 14px;
            text-transform: none !important;
        }

        label {
            font-family: var(--font-bold);
            color: var(--donkerblauw);

            .info {
                font-family: var(--font-regular);
                font-size: 0.75rem;
            }
        }

        .card-text .row:not(:last-of-type) {
            padding-bottom: 1rem;
            border-bottom: 1px solid rgba(12, 23, 50, 0.1);
        }
    }
}