:root {
    --wit: #ffffff;
    --groen: #1ca796;
    --donkerblauw: #041db7;
    --paars: #7d77ff;
    --blauw: #a5e2f4;
    --lichtblauw: #c9edf8;
    --oranje: #fe7225;
    --zwartblauw: #0c1732;
    --lila: #e0d9fb;
    --roze: #fae2fc;
    --lichtgeel: #fcffce;
    --geel: #f5ff77;
    --donkergeel: #dcaf22;
    --grijs: #f6f6f8;

    --success: #78c1a3;
    --danger: #f38989;

    --btn-border-radius: 50px;
    --border-radius: 25px;
    --navbar-width: 4.375rem;
    --navbar-mobile-height: 90px;
    --navbar-margin: 16px;

    --font-heavy: "MarkPro_Heavy";
    --font-bold: "MarkPro_Bold";
    --font-bold-italic: "MarkPro_Bold_Italic";
    --font-medium: "MarkPro_Medium";
    --font-medium-italic: "MarkPro_Medium_Italic";
    --font-black: "MarkPro_Black";
}
