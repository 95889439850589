.form-card {
    &.settings-card {
        .card-body {
            padding: 1rem 2rem 2rem 2rem;

            ul {
                padding: 0;
                line-height: 1.7;
                font-size: 14px;
                text-transform: none !important;
            }

            .setting-header {
                font-family: var(--font-bold);
                color: var(--zwartblauw);
                font-size: 1rem;
            }

            label {
                color: var(--donkerblauw);
                font-family: var(--font-bold);
                font-size: 0.875rem;
            }

            .card-text .row:not(:last-of-type) {
                border-bottom: none;
            }

            .card-text .setting:not(:last-of-type) {
                padding-bottom: 1rem;
                border-bottom: 1px solid rgba(12, 23, 50, 0.1);
            }
        }
    }
}