.divider {
    border-bottom: 1px solid rgba(12, 23, 50, 0.1);
}

.w-fit-content {
    width: fit-content;
}

.default-br {
    border-radius: var(--border-radius);
}

/* Font colors */
.text-wit {
    color: var(--wit);
}

.text-groen {
    color: var(--groen);
}

.text-donkerblauw {
    color: var(--donkerblauw);
}

.text-paars {
    color: var(--paars);
}

.text-blauw {
    color: var(--blauw);
}

.text-lightblauw {
    color: var(--lichtblauw);
}

.text-oranje {
    color: var(--oranje);
}

.text-zwartblauw {
    color: var(--zwartblauw);
}

.text-lila {
    color: var(--lila);
}

.text-roze {
    color: var(--roze);
}

.text-lichtgeel {
    color: var(--lichtgeel);
}

.text-geel {
    color: var(--geel);
}

/* Background colors */
.bg-wit {
    background: var(--wit) !important;
}

.bg-wit.see-through {
    background: rgba(255, 255, 255, 0.75) !important;
}

.bg-groen {
    background: var(--groen) !important;
}

.bg-groen.see-through {
    background: rgba(28, 167, 150, 0.5) !important;
}

.bg-donkerblauw {
    background: var(--donkerblauw) !important;
}

.bg-paars {
    background: var(--paars) !important;
}

.bg-blauw {
    background: var(--blauw) !important;
}

.bg-lichtblauw {
    background: var(--lichtblauw) !important;
}

.bg-oranje {
    background: var(--oranje) !important;
}

.bg-zwartblauw {
    background: var(--zwartblauw) !important;
}

.bg-lila {
    background: var(--lila) !important;
}

.bg-roze {
    background: var(--roze) !important;
}

.bg-lichtgeel {
    background: var(--lichtgeel) !important;
}

.bg-donkergeel {
    background: var(--donkergeel) !important;
}

.bg-geel {
    background: var(--geel) !important;
}

.bg-grijs {
    background: var(--grijs) !important;
}

.bg-transparent {
    background: transparent;
}