#public-navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .navbar-brand {
        display: inline-block;
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        margin-right: 1rem;
        font-size: 1.25rem;
        z-index: 1030;
        line-height: inherit;
        white-space: nowrap;

        img {
            height: 50px;
            width: auto;

            @media only screen and (max-width: 768px) {
                height: 30px;
            }
        }
    }

    .menu-actions {
        z-index: 1030;
        display: flex;
        gap: 8px;

        @media only screen and (max-width: 768px) {
            .portal-link {
                i {
                    display: none;
                }

                text-decoration: underline;
            }
        }

        button {
            background: transparent;
            border: none;
        }

        .toggle-menu-icon {
            color: var(--zwartblauw);
        }

        .portal-link {
            display: flex;
            align-items: center;
            padding: 3px 14px 4px 12px;
            line-height: 1;
            font-family: var(--font-bold);
            color: var(--zwartblauw);
            background-color: var(--wit);
            border-radius: var(--border-radius);

            &:hover,
            &:focus {
                background: var(--zwartblauw);
                color: var(--wit);
            }
        }

        .language-toggle {
            padding: 8px;
            font-size: 18px;
            font-family: var(--font-bold);
            color: var(--zwartblauw);

            &:hover,
            &:focus {
                background: transparent;
            }
        }

        &.open {
            .toggle-menu-icon {
                color: var(--wit);
            }

            .language-toggle {
                color: var(--wit);
            }
        }

        &.light {
            .toggle-menu-icon {
                color: var(--wit);
            }

            .language-toggle {
                color: var(--wit);
            }
        }
    }

    &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: var(--wit);
        box-shadow: 0 0 25px 5px rgba(12, 23, 50, 0.05);

        .navbar-brand {
            img {
                height: 35px;

                @media only screen and (max-width: 768px) {
                    height: 30px;
                }
            }
        }

        .menu-actions {
            .toggle-menu-icon {
                color: var(--zwartblauw);
            }

            .portal-link {
                color: var(--zwartblauw);
                background: transparent;
            }

            .language-toggle {
                color: var(--zwartblauw);
            }

            &.light {
                .toggle-menu-icon {
                    color: var(--zwartblauw);
                }

                .language-toggle {
                    color: var(--zwartblauw);
                }
            }

            &.open {

                .portal-link {
                    color: var(--zwartblauw);
                    background: var(--wit);
                }

                .toggle-menu-icon {
                    color: var(--wit);
                }

                .language-toggle {
                    color: var(--wit);
                }
            }
        }
    }

    .main-nav {
        top: 0;
        width: 57.5%;
        right: -100%;
        height: 100%;
        z-index: 1020;
        position: fixed;
        background: var(--zwartblauw);
        clip-path: polygon(15% 0%, 100% 0, 100% 100%, 0% 100%);

        a {
            width: fit-content;

            @media only screen and (max-width: 768px) {
                z-index: 1030;
            }
        }

        .main-nav-wrapper {
            width: 100%;
        }

        .nav {
            .nav-link {
                display: block;
                padding: 0.5rem 1rem;
                font-size: 18px;
                font-weight: 400;
                color: var(--wit);
                padding-left: 15% !important;
                width: 100%;

                &:hover {
                    background: rgba(255, 255, 255, 0.15);
                }
            }

            .nav-item-large {
                .nav-link {
                    font-size: 26px;
                    font-weight: 900;
                }

                &.last {
                    margin-bottom: 3rem;
                }
            }
        }

        &.open {
            right: 0;
        }

        .dropdown-menu {
            top: 0;
            bottom: 0;
            margin: 0;
            border: 0;
            padding: 0;
            left: 100%;
            width: 57.5%;
            height: 100%;
            display: flex;
            border-radius: 0;
            flex-direction: column;
            justify-content: center;
            position: fixed !important;
            align-content: space-around;
            background: var(--zwartblauw);

            .close-menu .nav-link {
                position: relative;
                padding-left: 2rem;
                margin-bottom: 1.5rem;

                span {
                    font-size: 20px;
                    font-weight: 800;
                }
            }

            &.show {
                left: 42.5%;
            }

            @media only screen and (max-width: 768px) {
                justify-content: flex-start;
                top: 100px;

                &.show {
                    left: 0%;
                    width: 100%;
                }
            }
        }

        @media only screen and (max-width: 768px) {
            width: 100%;
            clip-path: none;
            padding-top: 100px;
            height: 100%;
            align-items: flex-start !important;

            .nav {
                .nav-link {
                    padding-left: 10% !important;
                }
            }
        }
    }

    .main-nav-shadow {
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        visibility: hidden;
        background: var(--zwartblauw);

        &.show {
            z-index: 1000;
            opacity: 0.5;
            visibility: visible;
        }
    }
}
