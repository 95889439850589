.form-card {
    &.certifications {
        .card-body {
            .card-text div:not(:last-of-type) {
                padding-bottom: 0;
                border-bottom: none;
            }

            .card-text .certification-item-form {
                &:not(:last-of-type) {
                    padding-bottom: 0.5rem;
                }

                &.custom-certification {
                    padding: 1rem 0;
                    margin-bottom: 1rem;

                    &:not(:last-of-type) {
                        border-top: 1px solid rgba(12, 23, 50, 0.1);
                        border-bottom: 1px solid rgba(12, 23, 50, 0.1);
                    }
                }
            }
        }
    }

    &.languages {
        .card-body {
            .language-proficiency {
                margin-bottom: 0.875rem;

                label {
                    font-size: 0.875rem;
                    margin-bottom: 0;
                }

                select {
                    padding: 0.75rem;
                }

                &.view {
                    .language-proficiency-type {
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 0.5rem;
                    }

                    .language-proficiency-label {
                        color: var(--donkerblauw);
                        line-height: 1;
                    }

                    .levels {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                    }
                }
            }

            .card-text div:not(:last-of-type) {
                padding-bottom: 0;
                border-bottom: none;
            }

            .card-text .language-item-form {
                &:not(:last-of-type) {
                    padding-bottom: 1rem;
                    border-bottom: 1px solid rgba(12, 23, 50, 0.1);
                }

                &:not(:first-of-type) {
                    padding-top: 2rem;
                }
            }

            .card-text .language-item:not(:first-of-type) {
                padding-top: 1rem;
            }
        }
    }
}

.function-validators {
    background-color: var(--wit);
    border-radius: var(--border-radius);

    .feedback-item {
        // display: grid;
        // align-items: center;
        // gap: 8px;
        // grid-template-columns: 32px 140px 1fr 2fr;

        @media (max-width: 768px) {
            // grid-template-columns: 1fr 1fr;
        }

        .link {
            color: var(--donkerblauw);
            font-family: var(--font-medium);
            text-decoration: underline;
            width: fit-content;

            &.disabled {
                color: rgba(0, 0, 0, 0.3);
                text-decoration: none;
            }
        }
    }

    .feedback-item-form {
        background-color: var(--grijs);
        padding: 1rem 1.25rem 0.5rem 1.25rem;
        border-radius: var(--border-radius);
        margin-top: 1rem;

        input {
            border: 1px solid rgba(0, 0, 0, 0.2);
        }
    }
}
