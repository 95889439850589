.admin-overview {
    min-height: 100vh;
    min-height: 100dvh;
    background-color: var(--lichtblauw);

    table {
        border-radius: 8px;

        thead {
            background-color: var(--paars);
            color: var(--wit);
            border-radius: 8px;

            tr {
                th {
                    padding: 10px;

                    &:first-child {
                        border-top-left-radius: 8px;
                    }

                    &:last-child {
                        border-top-right-radius: 8px;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    vertical-align: middle;
                }
            }
        }
    }
}
