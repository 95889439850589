.function-container {
    .locked {
        opacity: 0.3;
        pointer-events: none;
    }

    .metadata {
        font-size: 13px;
    }

    .header-function-img {
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        clip-path: polygon(0% 0%, 100% 0, 100% 100%, 20% 100%);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .edit-action-bar {
        display: flex;
        justify-content: flex-end;
    }

    .skills-view-container {
        border-radius: var(--border-radius);

        .skills-selection {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            .sub-header {
                width: 100%;
                font-size: 0.875rem;
            }

            .label {
                display: flex;
                align-items: center;
                background-color: rgba(255, 255, 255, 1);
                padding: 4px 12px;
                border-radius: 5rem;
                border: 2px solid rgba(0, 0, 0, 0.2);

                .info {
                    padding: 0 9px;
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 50%;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }
    }
}
