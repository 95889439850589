.partner-footer {
    width: 100%;
    background-color: white;

    p {
        font-size: 13px;
    }

    .partners {
        a {
            margin: 1rem;

            img {
                height: 24px;
                width: auto;
            }
        }
    }
}

.page-footer {
    .footer-nav {
        .nav-link {
            padding: 0;
            font-size: 15px;
            line-height: 1.9;
            color: var(--zwartblauw);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .address {
        padding-left: 8px;
        border-left: 3px solid rgba(0, 0, 0, 0.1);
    }
}
