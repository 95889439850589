.loading-info {
    min-height: 100vh;
    min-height: 100dvh;
    background-color: var(--blauw);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    .loading-info__logo {
        width: 100%;
        text-align: center;
    }

    .loading-info__content {
        max-width: 600px;
        background-color: var(--lichtblauw);
        border-radius: var(--border-radius);
        text-align: center;
    }
}