.start-container {
    min-height: 100vh;
    min-height: 100dvh;
    background-color: var(--blauw);

    .title {
        font-family: var(--font-heavy);
        font-size: 2.5rem;
    }

    .intro {
        margin-top: 1rem;
    }

    .metadata {
        background-color: var(--wit);
        border-radius: var(--border-radius);
        padding: 2rem;

        .title {
            font-family: var(--font-heavy);
            color: var(--donkerblauw);
            font-size: 1.5rem;
        }
    }

    .info {

        .title {
            font-family: var(--font-heavy);
            color: var(--donkerblauw);
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }
    }

    .measurement-img {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 25%;
        }
    }
}
