.error-info {
    min-height: 100vh;
    min-height: 100dvh;
    background-color: var(--oranje);
    position: relative;

    .error-info__logo {
        padding: 2rem 1rem;
        background-color: rgba(0, 0, 0, 0.2);

        a {
            position: relative;

            img {
                height: 48px;
                width: auto;
                object-fit: cover;
                z-index: 1030;
            }
        }
    }

    .error-info__content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 80dvh;
        color: var(--wit);
    }
}
