/* Font Family: Mark Pro */

@font-face {
    font-family: 'MarkPro';
    src: url('../../public/assets/fonts/MarkPro.woff2') format('woff2'), url('../../public/assets/fonts/MarkPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MarkPro_Heavy';
    src: url('../../public/assets/fonts/FontFont_FF.Mark.Pro.Heavy.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'MarkPro_Bold';
    src: url('../../public/assets/fonts/FontFont_FF.Mark.Pro.Bold.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'MarkPro_Bold_Italic';
    src: url('../../public/assets/fonts/FontFont_FF.Mark.Pro.Bold.Italic.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'MarkPro_Medium';
    src: url('../../public/assets/fonts/FontFont_FF.Mark.Pro.Medium.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'MarkPro_Medium_Italic';
    src: url('../../public/assets/fonts/FontFont_FF.Mark.Pro.Medium.Italic.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'MarkPro_Black';
    src: url('../../public/assets/fonts/FontFont_FF.Mark.Pro.Black.otf') format('opentype');
    font-display: swap;
}

body {
    color: var(--zwartblauw);
    font-family: 'MarkPro' !important;
}

.font-regular {
    font-family: 'MarkPro' !important;
}

.font-heavy {
    font-family: 'MarkPro_Heavy' !important;
}

.font-bold {
    font-family: 'MarkPro_Bold' !important;
}

.font-bold-italic {
    font-family: 'MarkPro_Bold_Italic' !important;
}

.font-medium {
    font-family: 'MarkPro_Medium' !important;
}

.font-medium-italic {
    font-family: 'MarkPro_Medium_Italic' !important;
}

.font-black {
    font-family: 'MarkPro_Black' !important;
}