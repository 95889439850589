.scroll-to-top {
    position: fixed;
    bottom: 24px;
    right: 24px;

    background-color: var(--wit);
    border: none;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 2;

    i {
        color: var(--oranje);
    }

    &:hover {
        background-color: var(--oranje);
        i {
            color: var(--wit);
        }
    }

    @media screen and (max-width: 768px) {
        bottom: 102px;
        right: 12px;
    }
}