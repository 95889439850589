.swiper-container {
    
    --swiper-theme-color: rgb(214, 214, 214);
    --swiper-pagination-color: rgb(168, 168, 168);
    --swiper-scrollbar-size: 14px;
    --swiper-scrollbar-bg-color: rgba(255, 255, 255, 0.3);
    --swiper-scrollbar-drag-bg-color: rgba(255, 255, 255, 1);

    .swiper-button-prev, .swiper-button-next {
        color: var(--wit);
        opacity: 0.8;

        &.swiper-button-disabled {
            opacity: 0;
        }
    }
}
