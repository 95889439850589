/* Forms */

.form-label {
    padding-left: 0.5rem;

    &.no-edit {
        padding-left: 0;
    }
}

.form-control {
    height: auto;
    border: none;
    line-height: 1.0;
    border-radius: 26px;
    padding: 1rem 1.25rem;
    outline: none !important;
    box-shadow: none !important;

    &.readonly {
        color: rgba(0, 0, 0, 0.5);
        cursor: not-allowed;
    }
}

.form-toggle {
    display: flex;
    gap: 1rem;

    .form-toggle-option {
        background-color: var(--wit);
        color: var(--zwartblauw);
        padding: 0.5rem 2rem;
        border-radius: 5rem;
        border: 2px solid transparent;

        &.selected {
            border: 2px solid var(--zwartblauw);
        }
    }
}

.form-select {
    cursor: pointer;
    height: auto;
    border: none;
    line-height: 1.0;
    border-radius: 26px;
    padding: 1rem 1.25rem;
    outline: none !important;
    box-shadow: none !important;
}

.input-group-text {
    border: none;
    background-color: var(--wit);
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
}