.measurements-slide {
    width: 460px;

    @media only screen and (max-width: 576px) {
        width: 80vw;
    }

    .slide {
        position: relative;
        width: 100%;
        height: 300px;
        overflow: hidden;
        border-radius: var(--border-radius);
        margin-bottom: 2.5rem;

        .slide-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .slide-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 40px 20px 20px 20px;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
            color: white;
        }

        .slide-title {
            color: var(--wit);
            font-family: var(--font-black);
            font-size: 1.5rem;
            margin: 0;
        }

        .slide-intro {
            font-size: 1rem;
            margin: 0.5rem 0 1rem 0;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &.locked {
            opacity: 0.4;

            .locked-measurement {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 2;
                opacity: 0.4;

                i {
                    position: absolute;
                    top: 40%;
                    left: 50%;
                }
            }
        }

        .status {
            position: absolute;
            top: 20px;
            left: 20px;

            display: flex;
            align-items: center;
            width: fit-content;
            font-size: 0.875rem;
            padding: 0.25rem 0.75rem 0.25rem 0.5rem;
            line-height: 1;
            border-radius: 4rem;
            color: var(--zwartblauw);
            font-family: var(--font-medium-italic);
            background-color: var(--wit);

            i {
                position: relative;
                top: -1px;
            }

            p {
                position: relative;
                top: 1px;
            }
        }
    }
}
