@import "~bootstrap/scss/bootstrap-utilities";

.navigation-bar {
    z-index: 100;

    & {
        padding: 0.5rem;
        background-color: var(--wit);
        position: fixed;

        // Mobile
        @include media-breakpoint-only(xs) {
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: var(--navbar-mobile-height);
        }

        // Other devices
        @include media-breakpoint-up(sm) {
            width: var(--navbar-width);
            top: 10vh;
            border-radius: 5rem;
            height: fit-content;
            left: var(--navbar-margin);
        }

        // Wide screens
        @media screen and (min-width: 2000px) {
            left: initial;
            margin-left: -70px+-32px;
        }
    }

    .navigation-bar-nav {
        height: 100%;
        display: flex;
        flex-direction: row;

        // Other devices
        @include media-breakpoint-up(sm) {
            flex-direction: column;
        }

        .navigation-bar-user {
            text-align: center;
        }

        .navigation-bar-links {
            flex-grow: 1;

            // Mobile
            @include media-breakpoint-only(xs) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                .more-button {
                    cursor: pointer;
                }
    
                .mobile-popup {
                    &.open {
                        display: flex !important;
                        flex-direction: column;
                        gap: 1.5rem;
                        position: absolute;
                        height: fit-content;
                        width: 25%;
                        background-color: var(--wit);
                        border-radius: var(--border-radius) var(--border-radius) 0 0;
                        padding: 2rem 1rem;
                        top: -320px;
                        bottom: 0;
                        right: 0;
                        z-index: 90;
                    }
                }
            }

        }

        .navigation-bar-utilities {
            // Other devices
            @include media-breakpoint-up(sm) {
                margin-bottom: 1rem;
            }
        }

        li {
            text-align: center;
            flex: 25%;

            a {
                display: flex;
                align-items: center;
                flex-direction: column;

                &.active {
                    color: var(--oranje);

                    svg {
                        fill: var(--oranje);
                    }
                }
            }

            &:not(:last-child) {
                // Other devices
                @include media-breakpoint-up(sm) {
                    margin-bottom: 1rem;
                }
            }

            &:last-child {
                @include media-breakpoint-up(sm) {
                    margin-top: 3rem;
                }
            }

            span {
                font-size: 0.625rem;
                padding-top: 0.25rem;

                + span {
                    padding-top: 0;
                }
            }
        }
    }
}
