.wihv-modal {
    .modal-content {
        border: 0;
        border-radius: var(--border-radius);
        background-color: var(--wit);

        .modal-header {
            padding: 1.5rem;
            border: 0;
            border-radius: var(--border-radius) var(--border-radius) 0 0;
        }

        .modal-body {
            padding: 1.5rem;
            background: var(--lichtblauw);

            label {
                margin-bottom: 0;
            }
        }

        .modal-footer {
            border: 0;
            border-radius: 0 0 25px 25px;
        }
    }
}
