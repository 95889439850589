.feedback-skills-selection-wrapper {
    .knowledge-container,
    .skills-container {
        padding: 1rem;
        border-radius: var(--border-radius);

        .source-column,
        .function-column {
            .skill-type {
                font-size: 14px;
                font-family: var(--font-medium-italic);
                color: rgba(0, 0, 0, 0.3);
            }

            .source-options {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                max-height: 420px;
                overflow-y: auto;

                .skill-type {
                    width: 100%;
                    font-size: 14px;
                    font-family: var(--font-medium-italic);
                    color: rgba(0, 0, 0, 0.3);
                }

                .category-type {
                    width: 100%;
                    font-size: 14px;
                    font-family: var(--font-medium);
                    color: rgba(0, 0, 0, 0.4);
                }

                .feedback-row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 16px;

                    button {
                        border: none;
                        background-color: transparent;
                        border: 2px solid var(--zwartblauw);
                        border-radius: var(--border-radius);
                        padding: 0.25rem 1rem;
                        font-family: var(--font-medium);
                        margin-right: 8px;

                        &:disabled {
                            border-color: rgba(0, 0, 0, 0.1);
                        }

                        &:hover, &:focus {
                            &:enabled {
                                background-color: var(--oranje);
                            }
                        }
                    }
                }
            }

            .droppables-wrapper {
                border: 2px solid rgba(0, 0, 0, 0.2);
                border-radius: 8px;
            }

            .droppable-container {
                min-height: 200px;
                max-height: 200px;
                overflow-y: auto;
                width: 100%;

                &.optional {
                    border: 2px dashed rgba(0, 0, 0, 0.2);
                    border-radius: 8px;
                }
            }

            .option {
                user-select: none;
                display: flex;
                align-items: center;
                border: 2px solid rgba(0, 0, 0, 0.1);
                border-radius: 18px;
                background-color: var(--wit);
                position: relative;
                width: fit-content;
                font-size: 0.875rem;
                padding: 4px 12px 4px 12px;

                .start-icon {
                    i {
                        color: rgba(0, 0, 0, 0.3);
                    }
                }

                .drag-item-title {
                    font-family: var(--font-medium-italic);
                    color: rgba(0, 0, 0, 0.7);
                }

                .drag-item-info {
                    right: 5px;
                    padding: 0 9px;
                    background-color: rgba(0, 0, 0, 0.05);
                    border-radius: 50%;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }
    }
}
